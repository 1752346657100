import React from 'react';
// @ts-ignore
import image from '../../../../static/assets/regualtorisches.png';
import ServiceCard from './ServiceCard';

export default function Erfolgschance() {
    const heading = "Regulatorische Hürden als Erfolgschance";
    const text = `Unser umfassendes Normen-Verständnis sowie der spezifischen Anforderungen der Gebrauchstauglichkeitsakte hilft, das Risiko von Non-Compliance und damit verbundenen Konsequenzen deutlich zu reduzieren. Ihr profitiert von unserer Expertise, um potenzielle Fallstricke im Voraus zu identifizieren und zu umgehen. Konzentriert euch voll und ganz auf eure Fachexpertise, während wir die Compliance-Fragen für euch klären und sicherstellen, dass eure Gebrauchstauglichkeitsakte termingerecht und rechtskonform fertiggestellt wird.`;
    return (
        <ServiceCard image={image} heading={heading} text={text}></ServiceCard>
    )
}