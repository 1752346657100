import React from 'react';
// @ts-ignore
import image from '../../../../static/assets/optimierung-der-ressourcen.png';
import ServiceCardAkteSection4 from './ServiceCardAkteSection4';

export default function Abschlussbericht() {
    const heading = "5. Erstellung eines Abschlussberichts zur Gebrauchstauglichkeit";
    const text = `Zum Abschluss des Usability-Engineering-Prozesses erstellt man einen zusammenfassenden Bericht, der die Ergebnisse aller Usability-Maßnahmen dokumentiert und bewertet.`;
    return (
        <ServiceCardAkteSection4 heading={heading} text={text}></ServiceCardAkteSection4>
    )
}