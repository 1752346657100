import React from 'react';
import './aktesection2.scss';
// @ts-ignore
import done from '../../../static/assets/done.png';

interface Props {
    argument: string;
}

export default function Statement(props) {

    return (
        <div className="ContentSection4">
            <div className="IconWrapperSection4">
                <img src={done} alt="Done"/>
            </div>
            <div className="ArgumentSection4">
                {props.argument}
            </div>
        </div>
    );
}