import React from 'react';
// @ts-ignore
import image from '../../../../static/assets/optimierung-der-ressourcen.png';
import ServiceCardAkteSection4 from './ServiceCardAkteSection4';

export default function Benutzerschnittstelle() {
    const heading = "3. Spezifikation der Benutzerschnittstelle";
    const text = `Gemäß der Norm IEC 62366-1 ist dies ein wichtiger Bestandteil, der sich aus den Anforderungen der IEC 62304 für die Prozesse des Software-Lebenszyklus medizinischer Geräte ergibt. Auf der Grundlage der Anforderungen der Stakeholder werden Konzepte entwickelt und als Prototypen in Tools wie Figma visualisiert.`;
    return (
        <ServiceCardAkteSection4 heading={heading} text={text}></ServiceCardAkteSection4>
    )
}