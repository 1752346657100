import React from 'react';
// @ts-ignore
import './aktesection4.scss';
import Spezifikation from "./Cards/Spezifikation";
import Identifikation from "./Cards/Identifikation";
import Benutzerschnittstelle from "./Cards/Benutzerschnittstelle";
import Planung from "./Cards/Planung";
import Abschlussbericht from "./Cards/Abschlussbericht";
// @ts-ignore
import image from "../../../static/assets/prozessbild.png";

export default function AkteSection4() {

    return (
        <div className="ContainerAkteSection4">
            <div className="HeadingAkteSection4">Wie läuft der Usability Engineering Prozess ab?</div>
            <div className="ImageBorderAkte">
                <img className="ImageAkteSection6" src={image} alt="Gebrauchstauglichkeitsakte"/>
            </div>
            <br/>
            <a className="DownloadPosterButtonAkte" href={image} download="prozessbild.png">Prozessbild herunterladen</a>
            <div className="ServiceCards">
                <Spezifikation/>
                <Identifikation/>
                <Benutzerschnittstelle/>
                <Planung/>
                <Abschlussbericht/>
            </div>
        </div>
    );
}