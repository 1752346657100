import React from 'react';
import Heading from '../Heading/Heading';
import Section8 from "../Section8/Section8";
import Section9 from "../Section9/Section9";
import {Helmet} from "react-helmet";
import AkteSection1 from "../AkteSection1/AkteSection1";
import AkteSection2 from "../AkteSection2/AkteSection2";
import AkteSection3 from "../AkteSection3/AkteSection3";
import AkteSection4 from "../AkteSection4/AkteSection4";

export default function GebrauchstauglichkeitsaktePage() {

    return (
        <div> 
            <Helmet>
                <title>Digitale Medizinprodukte</title>
            </Helmet>
            <Heading/>
            <AkteSection1/>
            <AkteSection2/>
            <AkteSection3/>
            <AkteSection4/>
            <Section8/>
            <Section9/>
        </div>
    );
}