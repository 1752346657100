import React from 'react';
// @ts-ignore
import image from '../../../../static/assets/optimierung-der-ressourcen.png';
import ServiceCardAkteSection4 from './ServiceCardAkteSection4';

export default function Planung() {
    const heading = "4. Planung, Durchführung und Auswertung von formativen und summativen Evaluationen";
    const text = `a. Formative Evaluationen unterstützen den Designprozess durch iteratives Feedback und sind zentral für die nutzerzentrierte Produktentwicklung.`;
    const text2 = `b. Summative Evaluationen sind verpflichtend, sobald Risiken identifiziert wurden, und erfordern die Einbeziehung von Nutzern, um in realen Anwendungsszenarien zu testen, ob das Produkt sicher und wirksam ist.`;
    return (
        <ServiceCardAkteSection4 heading={heading} text={text} text2={text2}></ServiceCardAkteSection4>
    )
}