import React from 'react';
// @ts-ignore
import image from '../../../../static/assets/optimierung-der-ressourcen.png';
import ServiceCardAkteSection4 from './ServiceCardAkteSection4';

export default function Spezifikation() {
    const heading = "1. Spezifikation der Nutzung";
    const text = `Hierbei wird der Verwendungszweck, die Nutzungsumgebung sowie die Nutzergruppe präzisiert. Mittels Nutzungskontextanalyse kann man Nutzergruppen bei ihren Aufgaben beobachten.`;
    return (
        <ServiceCardAkteSection4 heading={heading} text={text}></ServiceCardAkteSection4>
    )
}