import React from 'react';
// @ts-ignore
import image1 from '../../../static/assets/avatare.png';
import './aktesection2.scss';
import Statement from './Statement';

import {statement1, statement2, statement3, statement4, statement5, statement6} from './TextStatements';
import {Link} from "gatsby";

export default function AkteSection2() {
    return (
        <div className="ContainerAkteSection2">
            <div className="TextblockAndImageAkteSection2">
                <div className="HeadingSection4">Für wen ist unsere Beratungsleistung?</div>
                <div className="ImageWrapperAkteSection2">
                    <img className="ImageAkteSection2" src={image1} alt="Personenavatare"/>
                </div>
                <div className="StatementsSection4">
                    <Statement argument={statement1}/>
                    <Statement argument={statement2}/>
                    <Statement argument={statement3}/>
                    <Statement argument={statement4}/>
                    <Statement argument={statement5}/>
                    <Statement argument={statement6}/>
                </div>
            </div>
            <div className="FooterSection4">Sprich uns an und wir machen einen unverbindlichen Termin.</div>
            <Link className="ContactFormLink KontaktButton MarginButton" to="/Kontakt">Kontaktformular öffnen</Link>
        </div>
    );
}