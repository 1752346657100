import React from 'react';
// @ts-ignore
import './aktesection3.scss';
import Optimierung from "./Cards/Optimierung";
import Steigerung from "./Cards/Steigerung";
import Marktzulassung from "./Cards/Marktzulassung";
import Erfolgschance from "./Cards/Erfolgschance";

export default function AkteSection3() {

    return (
        <div className="ContainerSection3">
            <div className="HeadingSection3">Welche Vorteile habt ihr?</div>
            <div className="ServiceCards">
                <Optimierung/>
                <Steigerung/>
                <Marktzulassung/>
                <Erfolgschance/>
            </div>
        </div>
    );
}