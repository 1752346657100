export let statement1 = "Du hast deine Idee schon formuliert und hast möglicherweise auch schon Ivestitionspartner gefunden?"

export let statement2 = "Du möchtest eine Erstberatung zu dem Thema Gebrauchstauglichkeitsakte und einen Überblick erhalten?"

export let statement3 = "Du startest gerade mit der Suche nach einem Entwicklungspartner?"

export let statement4 = "Du bist schon mitten in der Entwicklung und dir ist aufgefallen, dass du die Gebrauchstauglichkeitsakte noch gar nicht angelegt hast?"

export let statement5 = "Du möchtest mit der Gebrauchstauglichkeitsakte beginnen, aber weisst nicht wo du anfangen sollst?"

export let statement6 = "Der Normendschungel macht dich verrückt und du willst dich einfach nur auf die Fachlichkeit konzentrieren?"