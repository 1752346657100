import React from 'react';
// @ts-ignore
import image from '../../../../static/assets/optimierung-der-ressourcen.png';
import ServiceCard from './ServiceCard';

export default function Optimierung() {
    const heading = "Optimierung eurer Ressourcen";
    const text = `Mit unserer Beratung könnt ihr eure internen Ressourcen effizienter nutzen. Anstatt sich durch den komplexen Prozess der Dokumentenerstellung und die Einhaltung der Normen zu navigieren, ermöglichen wir es euch, euch auf eure Kernkompetenzen und die Weiterentwicklung eurer Produkte zu konzentrieren. Ihr beginnt die Erstellung eurer Gebrauchstauglichkeitsakte mit einem klaren Plan durch unsere fachkundige Anleitung – wir wissen genau, wo ihr anfangen müsst. Wir bieten euch Werkzeuge, Vorlagen und Best Practices, die den Prozess vereinfachen und beschleunigen.`;
    return (
        <ServiceCard image={image} heading={heading} text={text}></ServiceCard>
    )
}