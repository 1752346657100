import React from 'react';
import './ServiceCardAkteSection4.scss';

const ServiceCardAkteSection4 = ({heading, text, text2 = ""}) => {
    return (
        <div className="ServiceCardAkteSection4">
            <div className="CardHeadingAkteSection4">{heading}</div>
            <div className="CardTextAkteSection4">{text}</div>
            <div className="CardTextAkteSection4">{text2}</div>
        </div>
    )
}

export default ServiceCardAkteSection4;