import React from 'react';
// @ts-ignore
import image from '../../../../static/assets/steigerung.png';
import ServiceCard from './ServiceCard';

export default function Steigerung() {
    const heading = "Steigerung der Produktqualität und Benutzerzufriedenheit";
    const text = `Indem wir sicherstellen, dass die Gebrauchstauglichkeitsakte und die damit verbundenen Prozesse nicht nur als regulatorische Anforderung, sondern als integraler Bestandteil der Produktentwicklung betrachtet werden, tragen wir zur Steigerung der Produktqualität bei. Unsere Beratung führt zu einer besseren Ausrichtung eures Produktdesigns auf die Benutzerbedürfnisse, was letztlich die Zufriedenheit der Endnutzer und die Akzeptanz im Markt erhöht.`;
    return (
        <ServiceCard image={image} heading={heading} text={text}></ServiceCard>
    )
}