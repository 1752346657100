import React from 'react';
// @ts-ignore
import image from '../../../static/assets/gebrauchstauglichkeitsakte.png';
import './aktesection1.scss';
import {Link} from "gatsby";

export default function AkteSection1() {
    return (
        <div className="ContainerSection1">
            <div className="TextblockAndImage">
                <div className="Textblock">
                    <div className="Heading">Die fertige Gebrauchs&shy;tauglichkeitsakte nach IEC 62366 und MDR
                    </div>
                    <div className="Content">
                        <div className="subcontent1">
                            Wir sind darauf spezialisiert, für euch norm-konforme Gebrauchstauglichkeitsakten für MDRs und digitale
                            Gesundheitsanwendungen (DiGAs) fristgerecht zu erstellen. Diese Akten sind entscheidend für den Nachweis
                            der Einhaltung gesetzlicher Anforderungen an die Gebrauchstauglichkeit. Unsere Expertise umfasst:
                        </div>
                        <div>
                            <ul>
                                <li>Beratung zum idealen Zeitpunkt für den Start der verschiedenen Aktivitäten zur Aktenführung.</li>
                                <li>Zusammenstellung einer Übersicht aller notwendigen Dokumente für die Gebrauchstauglichkeitsakte.</li>
                                <li>Bereitstellung von Templates für das Ausfüllen der Dokumente.</li>
                                <li>Anleitung, welche Stakeholder einzubeziehen sind.</li>
                                <li>Einhaltung des Zeitplans, um sicherzustellen, dass die Akte fristgerecht fertiggestellt wird.</li>
                            </ul>
                        </div>
                        <div className="Bold">
                            Welche unserer Dienstleistungen passen am besten zu eurem Vorhaben?
                        </div>
                    </div>
                    <Link className="ContactFormLink" to="/Kontakt">Sprechen wir darüber</Link>
                </div>
                <div className="ImageWrapper">
                    <img className="Image" src={image} alt="Desk Art"/>
                </div>
            </div>
        </div>
    );
}