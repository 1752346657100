import React from 'react';
// @ts-ignore
import image from '../../../../static/assets/effizientere-marktzulassung.png';
import ServiceCard from './ServiceCard';

export default function Marktzulassung() {
    const heading = "Effizientere Marktzulassung";
    const text = `Durch unsere Beratungsleistung erhaltet ihr eine maßgeschneiderte Unterstützung bei der Erstellung eurer Gebrauchstauglichkeitsakte, was nicht nur den Zulassungsprozess für eure Medizinprodukte und DiGAs beschleunigt, sondern auch potenzielle Verzögerungen durch Nachbesserungen vermeidet. Wir stellen sicher, dass alle regulatorischen Anforderungen von Anfang an erfüllt werden, was eine schnellere Markteinführung ermöglicht.`;
    return (
        <ServiceCard image={image} heading={heading} text={text}></ServiceCard>
    )
}