import React from 'react';
// @ts-ignore
import image from '../../../../static/assets/optimierung-der-ressourcen.png';
import ServiceCardAkteSection4 from './ServiceCardAkteSection4';

export default function Identifikation() {
    const heading = "2. Identifikation von gefährdungsbezogenen Nutzungsszenarien";
    const text = `Ziel ist es, durch diese Analyse mögliche Anwendungsfehler und nutzungsbedingte Risiken zu erkennen und daraus Nutzungsanforderungen abzuleiten.`;
    return (
        <ServiceCardAkteSection4 heading={heading} text={text}></ServiceCardAkteSection4>
    )
}